<template>
  <div class="flex gap-4 py-2 overflow-visible overflow-x-auto snap-x">

    <create-item :title="$t('dashboard.feeling_today', { username: getUsername })" @onPlusClick="openDailyMoodModal"
      class="relative flex-shrink-0 snap-start" :class="{
        'w-11/12': notes.length,
  'w-full': notes.length,
      }">
      <happy-mood class="absolute top-0 z-10 opacity-25 -right-7 h-36" />
    </create-item>

    <!-- <div class="relative flex-shrink-0 w-11/12 h-32 p-4 overflow-hidden bg-white rounded-lg shadow-md snap-start text-brand-dark"
      :key="note.uuid" v-for="note in notes">
      <p class="font-bold">
        {{ $t(`moods.${note.mood}`) }}
      </p>

      <p class="text-gray-400" v-if="note.content">{{ note.content }}</p>

      <mood-icon class="absolute opacity-25 -right-20 -top-10 h-52 w-52" :value="note.mood" />
    </div> -->

    <div
      class="relative flex flex-col justify-between flex-shrink-0 w-11/12 h-32 p-4 overflow-hidden bg-white rounded-lg shadow-md snap-start text-brand-dark"
      v-if="lastFilledOut">
      <div>
        <p class="font-bold">{{ $t("moods.last_filled") }}</p>
        <p>
          <span class="font-semibold uppercase">{{ $t("shared.mood") }}:</span>
          {{ $t(`moods.${lastFilledOut.mood}`) }}
        </p>
      </div>

      <p class="text-gray-400">
        {{
        $moment
        .duration(
        $moment(lastFilledOut.created_at).diff($moment()),
        "milliseconds"
        )
        .humanize(true)
        }}
      </p>

      <mood-icon class="absolute opacity-25 -right-20 -top-10 h-52 w-52" :value="lastFilledOut.mood"
        v-if="lastFilledOut.mood" />
    </div>
  </div>
</template>

<script>
import api from "../../utils/api";
import { modalTypes } from "../../utils/constants";
import MoodIcon from "../MoodIcon";
import { mapGetters } from "vuex";
import CreateItem from "@/components/Dashboard/CreateItem";
import HappyMood from "assets/moods/mood_satisfied.svg";

export default {
  name: "Journal",
  components: { MoodIcon, CreateItem, HappyMood },
  mounted() {
    this.init();
  },

  data() {
    return {
      notes: [],
      lastFilledOut: null,
    };
  },

  computed: {
    ...mapGetters({
      getUsername: "getUsername",
    }),
  },

  methods: {

    openDailyMoodModal() {
      this.$store.commit("TOGGLE_MODAL", {
        modalType: modalTypes.DAILY_MOOD_MODAL,
        modalState: true,
      });
    },
    
    async init() {
      try {
        const { data } = await api.get("/api/journal/dashboard", {
          params: {
            take: 3,
          },
        });

        if (data.success) {
          this.notes = data.data.notes;
          this.lastFilledOut = data.data.last_filled_out;
          // console.log(data);
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
