import api from "utils/api";
const resource = "/api/dashboard";

export default {
  async init(language) {
    try {
      const { data } = await api.get(resource, {
        params: {
          language,
        },
      });
      return data;
    } catch (e) {
      return e.message;
    }
  },
};
