<template>
  <div class="w-full">
    <div
      class="flex items-stretch w-full gap-4 p-0 overflow-visible overflow-x-auto xs:overflow-hidden xs:p-2 xs:gap-0 xs:space-x-4 xs:flex-row xs:justify-evenly text-brand-dark xs:snap-x">


      <div
        class="relative flex flex-col justify-between flex-shrink-0 w-11/12 h-auto p-4 overflow-hidden bg-white rounded-lg shadow-md xs:w-1/2 xs:snap-start xs:snap-none">
        <div>
          <p class="text-base font-semibold"><a href="/events">{{ $t("dashboard.stats.events_future") }}</a></p>
          <p class="text-sm text-gray-400" v-if="getEventStatCounts.today">
            <span class="font-medium">{{ $t("dashboard.stats.events_today") }}:</span>
            <span class="font-bold text-brand-dark">
              {{ getEventStatCounts.today }}
            </span>
          </p>
        </div>
        <p class="self-end mt-4 text-2xl font-bold">{{ (getEventStatCounts.future + getEventStatCounts.today) }}</p>

        <icon-events class="absolute w-40 h-40 opacity-25 fill-current -right-8 -top-4" :class="{
          'text-red-400': !getEventStatCounts.today,
          'text-brand-primary': !!getEventStatCounts.today
        }" />
      </div>
      <div
        class="relative flex flex-col justify-between flex-shrink-0 w-11/12 h-auto p-4 overflow-hidden bg-white rounded-lg shadow-md xs:w-1/2 xs:snap-start xs:snap-none">
        <p class="text-base font-semibold">{{ $t("dashboard.stats.events_participated") }}</p>
        <p class="self-end mt-4 text-2xl font-bold">{{ getEventStatCounts.past }}</p>

        <icon-events class="absolute w-40 h-40 text-gray-200 opacity-25 fill-current -right-8 -top-4" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import IconEvents from "assets/icons/event-map-pin.svg";

export default {
  name: "EventStats",

  components: {
    IconEvents,
  },

  computed: { 
    ...mapGetters(['getEventStatCounts'])
  },
};
</script>

<style scoped>
</style>
