<template>
  <div @click="handleRouteChange" class="relative flex h-32 p-4 overflow-hidden bg-white rounded-lg shadow-md"
    :class="{ 'opacity-25': disabled }">
    <div>
      <h4 v-if="event.is_future_event" class="font-bold uppercase text-brand-dark">
        {{ $t("events.coming_up") }}
      </h4>
      <h4 class="font-medium text-brand-dark" v-else>
        {{
        $t(
        isCheckedIn
        ? "dashboard.checked_in_party"
        : "dashboard.ready_to_party",
        { username: getUsername }
        )
        }}
      </h4>
      <p class="text-xs text-gray-400">{{ event.name }}</p>
    </div>

    <div class="absolute flex items-center h-10 space-x-2 bottom-4 right-4">
      <t-button v-if="!event.is_future_event" as="router-link" :to="{
          name: 'my-event',
          params: { uuid: event.uuid, autoCheckin: true },
        }" class="z-20 flex items-center justify-center w-10 h-full m-0 bg-white rounded-full" :class="{
          'bg-white shadow-md': !isCheckedIn,
          'bg-brand-lightblue shadow-primary-blue': isCheckedIn && currentEvent === event.uuid,
        }" :disabled="disabled" variant="link">
        <icon-check-in class="w-5 h-5 text-brand-dark" />
      </t-button>
      <t-button :to="{
          name: 'my-event-checklist',
          params: { uuid: event.uuid },
        }" :disabled="isCheckedIn && currentEvent !== event.uuid"
        class="z-20 flex items-center justify-center w-10 h-full m-0 bg-white rounded-full shadow-md" variant="link">
        <icon-list class="w-5 h-5 text-brand-dark" />
      </t-button>
      <t-button :to="{
          name: 'my-event-preparation-tool',
          params: { uuid: event.uuid },
        }" class="z-20 flex items-center justify-center w-10 h-full m-0 bg-white rounded-full shadow-md"
        variant="link">
        <icon-lightbulb class="w-5 h-5 text-brand-dark" />
      </t-button>
    </div>

    <icon-events class="absolute z-10 opacity-25 fill-current -top-2 -right-10 h-36 text-brand-primary" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import IconLightbulb from "assets/icons/lightbulb.svg";
import IconCheckIn from "assets/icons/check-in_location.svg";
import IconEvents from "assets/icons/event-map-pin.svg";
import IconList from "assets/icons/clipboard.svg";

export default {
  components: {
    IconLightbulb,
    IconList,
    IconCheckIn,
    IconEvents,
  },

  props: {
    event: {
      type: Object,
    },
  },

  methods: {
    handleRouteChange() {
      if (this.currentEvent && this.currentEvent === this.event.uuid) {
        this.$router.push({
          name: "my-event",
          params: { uuid: this.event.uuid },
        });
      }
    },
  },

  computed: {
    ...mapGetters(["getUsername", "isCheckedIn", "currentEvent"]),
    disabled() {
      return this.currentEvent && this.currentEvent !== this.event.uuid;
    },
  },
};
</script>

<style></style>
