<template>
  <div class="relative mb-16 home-page">
    <div
      class="flex flex-col w-full max-w-screen-sm px-8 mx-auto md:max-w-screen-md md:p-10 lg:max-w-screen-lg xl:max-w-screen-xl">
      <div class="flex items-center mb-4 space-x-4">
        <router-link to="/profile">
          <avatar v-if="isAuthenticated && isProfileLoaded" class="w-12 h-12" />
        </router-link>
        <h1 class="text-2xl font-bold">
          {{ greeting }}


          <span v-if="isAuthenticated && isProfileLoaded" class="block text-xl font-semibold">{{ getUsername }}</span>
        </h1>
      </div>

      <loading v-if="loading" />

      <div v-else class="gap-8 lg:grid lg:grid-cols-7">
        <div class="w-full col-span-4">
          <div class="my-4">
            <h2 class="mb-2 text-xl font-bold">Recent</h2>

            <div class="w-full">
              <div v-if="!!articles || !!highlighted" class="flex py-2 overflow-visible overflow-x-auto snap-x">
                <template v-if="!!highlighted">
                  <div v-for="article in highlighted" class="flex-shrink-0 overflow-hidden snap-start" :class="{
                    'w-11/12': highlighted.length + (!!articles ? 1 : 0) > 1,
                    'w-full': highlighted.length + (!!articles ? 1 : 0) <= 1,
                  }" :key="article.id">
                    <router-link :to="{
                      name: 'article-single',
                      params: { id: article.id },
                    }" class="relative flex h-40 p-4 mr-4 overflow-hidden bg-white rounded-lg shadow-md md:h-52">
                      <div class="relative z-30 flex flex-col justify-between">
                        <div>
                          <h2 class="font-semibold">
                            {{ article.title }}
                          </h2>
                          <p class="text-xs font-medium">
                            {{ article.excerpt }}
                          </p>
                        </div>

                        <router-link class="text-xs" :to="{
                          name: 'article-single',
                          params: { id: article.id },
                        }">{{ $t("read_more") }}</router-link>
                      </div>

                      <img :src="
                        article.image
                          ? article.image
                          : require('assets/articles/placeholder.jpg')
                      " class="absolute inset-0 z-10 object-cover w-full h-full opacity-40" />
                    </router-link>
                  </div>
                </template>

                <template v-if="!!articles">
                  <div v-for="article in articles" class="flex-shrink-0 overflow-hidden snap-start" :class="{
                    'w-11/12': articles.length + (!!highlighted ? 1 : 0) > 1,
                    'w-full': articles.length + (!!highlighted ? 1 : 0) <= 1,
                  }" :key="article.id">
                    <router-link :to="{
                      name: 'article-single',
                      params: { id: article.id },
                    }" class="relative flex h-40 p-4 mr-4 overflow-hidden bg-white rounded-md shadow-lg md:h-52">
                      <div class="relative z-30 flex flex-col justify-between">
                        <div>
                          <h2 class="font-semibold">
                            {{ article.title }}
                          </h2>
                          <p class="text-xs font-medium">
                            {{ article.excerpt }}
                          </p>
                        </div>

                        <router-link class="text-xs" :to="{
                          name: 'article-single',
                          params: { id: article.id },
                        }">{{ $t("read_more") }}</router-link>
                      </div>

                      <img :src="
                        article.image
                          ? article.image
                          : require('assets/articles/placeholder.jpg')
                      " class="absolute inset-0 z-10 object-cover w-full h-full opacity-40" />
                    </router-link>
                  </div>
                </template>
              </div>
            </div>
          </div>

          <template v-if="isAuthenticated && isProfileLoaded">
            <div class="flex flex-col space-y-4">
              <section>
              <a href="/events">
                <dashboard-event-stats />
                <!-- <dashboard-event-stats :future-events="upcoming_events.length"
                  :first-ongoing-event="upcoming_events[0] || null" /> -->
              </a>
              </section>

              <notification-slider />

              <section>
                <h2 class="mb-2 text-xl font-bold">{{ $t("journal.title") }}</h2>
                <dashboard-journal />
              </section>

              <section>
                <h2 class="mb-2 text-xl font-bold">
                  {{ $t("events.title--upcoming-events") }}
                </h2>
                <loading v-if="loading" />

                <div v-else-if="ongoing_events.length">
                  <div class="flex w-full py-2 overflow-visible overflow-x-auto snap-x">
                    <dashboard-event-item class="flex-shrink-0 mr-4 snap-start last:mr-0" :class="{
                      'w-11/12': events.length > 1,
                      'w-full': events.length <= 1,
                    }" v-for="event in events" :key="event.uuid" :event="event" />
                  </div>
                </div>

                <template v-else>
                  <create-item :title="$t('dashboard.create_event')" @onPlusClick="createEvent">
                    <icon-events
                      class="absolute z-10 opacity-25 fill-current -top-2 -right-12 h-36 text-brand-primary" />
                  </create-item>
                </template>
              </section>


              <section class="w-full mt-4" v-if="getBuddies && !getBuddies.length">
                <h2 class="mb-2 text-xl font-bold">
                  {{ $t("profile.safety-buddy") }}
                </h2>
                <dashboard-buddy />
              </section>

              <!-- <section class="w-full mt-4">
              <h2 class="mb-2 text-xl font-bold">
                {{ $t("profile.preferences-notifications.title") }}
              </h2>

              <div>
                <notification-preferences />
              </div>
            </section> -->
            </div>
          </template>

          <template v-else>
            <div class="relative flex flex-col w-full gap-4 px-4 py-6 space-y-4 bg-white rounded-md shadow-lg">

              <i18n tag="p" path="dashboard.unauthenticed_introduction">
                <template v-slot:link>
                  <router-link class="underline" :to="{ name: 'register' }">{{
                  $t("dashboard.link")
                  }}</router-link>
                </template>
              </i18n>

              <ul class="p-2 space-y-2 text-sm rounded-md shadow-inner">
                
                
                
                
                
                <li class="flex flex-row items-center space-x-2">
                  <icon-check
                    class="h-4 w-4 rounded-full border border-brand-dark bg-brand-primary fill-current p-0.5 text-brand-dark">
                  </icon-check>
                  <span>{{
                  $t("dashboard.feature_list.plan_your_events")
                  }}</span>
                </li>
                <li class="flex flex-row items-center space-x-2">
                  <icon-check
                    class="h-4 w-4 rounded-full border border-brand-dark bg-brand-primary fill-current p-0.5 text-brand-dark">
                  </icon-check>
                  <span>{{
                  $t("dashboard.feature_list.track_your_mood")
                  }}</span>
                </li>
                <li class="flex flex-row items-center space-x-2">
                  <icon-check
                    class="h-4 w-4 rounded-full border border-brand-dark bg-brand-primary fill-current p-0.5 text-brand-dark">
                  </icon-check>
                  <span>{{
                  $t("dashboard.feature_list.prepare_for_an_event")
                  }}</span>
                </li>
                <li class="flex flex-row items-center space-x-2">
                  <icon-check
                    class="h-4 w-4 rounded-full border border-brand-dark bg-brand-primary fill-current p-0.5 text-brand-dark">
                  </icon-check>
                  <span>{{
                  $t("dashboard.feature_list.wirte_in_your_journal")
                  }}</span>
                </li>
                <li class="flex flex-row items-center space-x-2">
                  <icon-check
                    class="h-4 w-4 rounded-full border border-brand-dark bg-brand-primary fill-current p-0.5 text-brand-dark">
                  </icon-check>
                  <span>{{
                  $t("dashboard.feature_list.monitor_your_participation")
                  }}</span>
                </li>
               
              </ul>
              <div
                class="flex flex-col items-center justify-around gap-4 p-2 font-medium text-center lg:flex-row">

                <p class="inline-flex flex-col w-auto space-y-2 text-sm text-center lg:w-1/2">
                  <span>{{ $t("auth.callout--register") }}</span>
                  <router-link is="t-button" variant="primary" :to="{ name: 'register' }">{{
                  $t("auth.btn--create-account") }}</router-link>
                </p>
                <p class="inline-flex flex-col w-auto space-y-2 text-sm text-center lg:w-1/2">
                  <span>{{ $t("auth.callout--login") }}</span>
                  <router-link is="t-button" variant="secondary" :to="{ name: 'login' }">{{ $t("auth.btn--login") }}
                  </router-link>
                </p>
                
              </div>
            </div>

          </template>
        </div>

        <template>
          <div class="col-span-3" v-if="screenWidth > 1024">
            <template v-if="isAuthenticated && isProfileLoaded">
              <div class="flex flex-col gap-8 p-4 bg-white shadow-md rounded-2xl">
                <div>
                  <h2 class="mb-2 text-xl font-bold">
                    {{ $t("navigation.journal") }}
                  </h2>

                  <div>
                    <div class="space-y-2" v-if="notes.length">
                      <note-item class="shadow" v-for="note in notes" :key="note.uuid" :note="note"></note-item>
                    </div>

                    <div v-else>
                      <div class="relative flex items-center justify-center my-12">
                        <icon-notebook class="relative z-20 w-20 h-20" />
                        <blob class="absolute z-10 w-64 h-64 opacity-75" />
                      </div>

                      <div class="text-sm text-center">
                        {{ $t("journal.empty--journal") }}
                      </div>
                    </div>
                  </div>

                  <div class="mt-4 text-center">
                    <t-button variant="tertiary" to="/journal" class="w-4/5">{{
                    $t("dashboard.see_all_notes")
                    }}</t-button>
                  </div>
                </div>

                <div>
                  <h2 class="col-span-3 mb-2 text-xl font-bold">
                    {{ $t("event.checklist") }}
                  </h2>

                  <div>
                    <div class="space-y-2" v-if="todos.length">
                      <checklist-item :task="todo" v-for="todo in todos" :key="todo.uuid"></checklist-item>
                    </div>

                    <div v-else>
                      <div class="relative flex items-center justify-center my-12">
                        <icon-checklist class="relative z-20 w-20 h-20" />
                        <blob class="absolute z-10 w-64 h-64 opacity-75" />
                      </div>

                      <div class="text-sm text-center">
                        {{ $t("checklist.empty--checklist") }}
                      </div>
                    </div>
                  </div>

                  <div class="mt-4 text-center">
                    <t-button variant="tertiary" to="/profile/checklist" class="w-4/5">{{
                    $t("dashboard.see_full_checklist") }}</t-button>
                  </div>
                </div>
              </div>
            </template>

            <div class="mt-4 space-y-4">
              <router-link class="flex items-center p-4 text-white rounded-lg bg-brand-navblue" :to="route.path"
                v-for="route in routes" :key="route.translationKey">
                <component :is="route.icon" class="w-10 mr-8 text-white fill-current"></component>

                <p class="flex-1">
                  {{ $t(`navigation.${route.translationKey}`) }}
                </p>

                <icon-right class="w-6"></icon-right>
              </router-link>
            </div>

            <section class="mt-4">
              <h2 class="mb-2 text-xl font-bold">
                {{ $t("pages.partners") }}
              </h2>

              <div
                class="grid items-center content-center grid-cols-2 gap-4 p-4 bg-white shadow-md place-items-center rounded-xl">
                <img class="object-contain h-40 col-span-2 w-80"
                  src="https://budd.be/wp-content/uploads/2022/02/logo-ITG.jpg" alt="ITG" />
                <img class="object-contain h-40 w-80"
                  src="https://budd.be/wp-content/uploads/2022/02/Vlaamse-overheid.png" alt="Vlaamse overheid" />
                <img class="object-cover h-40 w-80" src="https://budd.be/wp-content/uploads/2022/02/logo-UA.jpg"
                  alt="Universiteit Antwerpen" />
                <img class="object-cover h-40 w-80" src="https://budd.be/wp-content/uploads/2022/02/logo-Sensoa.jpg"
                  alt="Sensoa" />
                <img class="object-cover h-40 w-80" src="https://budd.be/wp-content/uploads/2022/02/logo-VAD.jpg"
                  alt="VAD" />
              </div>
            </section>
          </div>

          <!-- <two-days-after-event v-if="!!notFilledEvent" :event="notFilledEvent" /> -->
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { sortBy as _sortBy } from "lodash";
import { VueOfflineMixin } from "vue-offline";
import { mapGetters } from "vuex";

import { modalTypes } from "@/utils/constants";

import CreateItem from "@/components/Dashboard/CreateItem";
import DashboardEventItem from "@/components/Dashboard/EventItem";
import ChecklistItem from "@/components/Checklist/ChecklistItem";
// import NotificationPreferences from "@/components/Dashboard/NotificationPreferences";
import NoteItem from "@/components/Journal/NoteItem";
import DashboardEventStats from "@/components/Dashboard/EventStats";
import NotificationSlider from "@/components/Dashboard/NotificationSlider";
import DashboardJournal from "@/components/Dashboard/Journal";
import DashboardBuddy from "@/components/Dashboard/Buddy";

import IconEvents from "@/assets/icons/event-map-pin.svg";
import IconNotebook from "@/assets/icons/paper-clock.svg";
import IconChecklist from "@/assets/icons/clipboard.svg";
import HappyMood from "@/assets/moods/mood_satisfied.svg";
import IconCheck from "@/assets/icons/check.svg";
import Blob from "@/assets/icons/blob.svg";
import IconRight from "@/assets/icons/chevron-right.svg";
import IconChemInfo from "@/assets/icons/drugsinfo.svg";
import IconArticlesBookLines from "@/assets/icons/articles-book-lines.svg";
import IconPeople from "@/assets/icons/people.svg";
import IconDrugcombo from "@/assets/icons/drug-pills.svg";
import IconEmergencyHelp from "@/assets/icons/emergency-help.svg";
import IconAssistance from "@/assets/icons/assistance.svg";


import Repository from "@/repositories/RepositoryFactory";
import DashboardRepository from "@/repositories/DashboardRepository";
import MyEventRepository from "@/repositories/MyEventRepository";

const EventRepository = Repository.get("my-events");
const TodoRepository = Repository.get("todos");
const JournalRepository = Repository.get("journal");

export default {
  name: "Home",

  mixins: [VueOfflineMixin],

  components: {
    // NotificationPreferences,
    ChecklistItem,
    DashboardEventItem,
    IconEvents,
    IconCheck,
    HappyMood,
    CreateItem,
    NoteItem,
    DashboardEventStats,
    DashboardJournal,
    DashboardBuddy,
    IconChecklist,
    IconNotebook,
    Blob,
    IconRight,
    IconChemInfo,
    IconArticlesBookLines,
    IconPeople,
    IconDrugcombo,
    IconEmergencyHelp,
    IconAssistance,
    NotificationSlider,
  },

  watch: {
    getProfile: {
      deep: true,
      handler(profile) {
        this.$i18n.locale = profile.language;
      },
    },
  },

  data() {
    return {
      events: [],
      articles: [],
      highlighted: {},
      loading: false,
      creatingRandomEvent: false,
      proposedTips: [],
      tip_loading: false,
      todos: [],
      notes: [],
      screenWidth: window.innerWidth,
      notFilledEvent: null,
      THIRTY_MIN_IN_MS: 1000 * 60 * 30,
      polling: null,
      routes: [
        {
          path: "/info/drugcombos",
          translationKey: "drugcombos",
          icon: "IconDrugcombo",
        },
        {
          path: "/info/chems",
          translationKey: "chems",
          icon: "IconChemInfo",
        },
        {
          path: "/info/assistance",
          translationKey: "assistance",
          icon: "IconAssistance",
        },
        {
          path: "/info/emergency",
          translationKey: "emergency",
          icon: "IconEmergencyHelp",
        },
        {
          path: "/info/articles",
          translationKey: "articles",
          icon: "IconArticlesBookLines",
        },
        {
          path: "/info/articles/chemsex",
          translationKey: "chemsex_stories",
          icon: "IconPeople",
        },
      ],
    };
  },

  methods: {
    getEvents: async function () {
      this.loading = true;
      const { data } = await EventRepository.today();
      const {
        data: { data: futureEvents },
      } = await EventRepository.future();
      this.events = [...data.events, ...futureEvents];

      // const future = await EventRepository.future(); //!
      // // console.log('future', future.data);
      // this.events = future.data.data;

      this.loading = false;
    },

    async initDashboard() {
      const response = await DashboardRepository.init(this.$i18n.locale);
      if (response.success) {
        this.articles = response.data.articles;
        this.highlighted = response.data.highlighted;
      }
    },

    openDailyMoodModal() {
      this.$store.commit("TOGGLE_MODAL", {
        modalType: modalTypes.DAILY_MOOD_MODAL,
        modalState: true,
      });
    },

    createEvent() {
      this.$router.push({
        name: "my-events",
        params: { formOpenAtLoad: true },
      });
    },

    getDesktopData() {
      if (!this.isAuthenticated) return;

      TodoRepository.all().then((response) => {
        const todos = response.data.todos;
        this.todos = _sortBy(todos, "description").slice(0, 3);
      });
      JournalRepository.getNotes(1).then((response) => {
        const notes = response.data.data;
        this.notes = _sortBy(notes, "created_at").slice(0, 3);
      });
    },

    openTwoDaysAfterModal() {
      this.$store.commit("TOGGLE_MODAL", {
        modalType: modalTypes.TWO_DAYS_AFTER_EVENT_MODAL,
        modalState: true,
      });
    },

    async notifyUserTwoDaysAfterEvent() {
      if (this.appLoaded) return;

      const { data } = await MyEventRepository.getEventsWithoutAfterMood();
      if (data.success) {
        const events = data.data.filter((event) => !event.log.length);

        if (events.length) {
          this.$nextTick(() => {
            this.notFilledEvent = events[0];
            this.openTwoDaysAfterModal();
            this.$store.commit("general/SET_APP_LOADED", true);
          });
        }

        this.polling = setTimeout(() => {
          this.$store.commit("general/SET_APP_LOADED", false);
          this.notifyUserTwoDaysAfterEvent();
        }, this.THIRTY_MIN_IN_MS);
      }
    },
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "isAuthenticated",
      isProfileLoaded: "isProfileLoaded",
      currentEvent: "currentEvent",
      getUsername: "getUsername",
      getBuddies: "getBuddies",
      getProfile: "getProfile",
      appLoaded: "general/appLoaded",
      hasActiveNotifications: "hasActiveNotifications",
    }),

    ongoing_events() {
      return _sortBy(this.events, ["date_from", "date_until", "name"]);
    },


    upcoming_events() {
      if (!(this.events instanceof Array) || !this.events.length) return;
      var events = this.events.filter((event) => {
        if (event.date_until) {
          return (
            new Date(new Date(event.date_until).setHours(23, 23, 59, 99)) >=
            this.now
          );
        }
        return (
          new Date(new Date(event.date_from).setHours(23, 23, 59, 99)) >=
          this.now
        );
      });

      return _sortBy(events, ["date_from", "date_until", "name"]);
    },

    greeting() {
      const hours = new Date().getHours();

      switch (true) {
        case hours >= 6 && hours < 12:
          return this.$t("home.good_morning");
        case hours < 18 && hours >= 12:
          return this.$t("home.good_afternoon");
        case hours >= 18 && hours < 22:
          return this.$t("home.good_evening");
        default:
          return this.$t("home.good_night");
      }
    },
  },
  created: function () {
    this.initDashboard(); // loads articles

    if (this.isAuthenticated) {
      this.getEvents();
      this.$store.dispatch("canParticipateInAssessmentQuiz");
    }

    window.addEventListener("resize", () => {
      this.screenWidth = window.innerWidth;
    });

    if (this.screenWidth > 768) {
      this.getDesktopData();
    }
  },

  mounted() {
    // this.notifyUserTwoDaysAfterEvent();
  },

  beforeDestroy() {
    window.removeEventListener("resize", () => {
      this.screenWidth = window.innerWidth;
    });

    if (this.polling) {
      clearTimeout(this.polling);
    }
  },
};
</script>
