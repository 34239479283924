var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasActiveNotifications)?_c('section',{staticClass:"relative flex items-stretch gap-4 py-2 overflow-visible overflow-x-auto snap-x text-brand-dark"},_vm._l((Object.entries(_vm.getNotifications || {})),function(ref){
var notification_key = ref[0];
var notification = ref[1];
return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!!notification.active),expression:"!!notification.active"}],key:notification_key,staticClass:"relative flex flex-col flex-shrink-0 h-auto p-4 overflow-hidden bg-white rounded-lg shadow-md snap-start",class:{
      'w-11/12': _vm.countActiveNotifications > 1,
      'w-full': _vm.countActiveNotifications == 1,
    }},[_c('button',{key:"popup-close",staticClass:"absolute z-40 items-stretch w-5 h-5 font-bold text-brand-dark-75 hover:text-brand-dark focus:outline-none top-2 right-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.dismissNotification(notification_key)}}},[_c('icon-close')],1),_c('p',{staticClass:"max-w-full text-base text-semibold"},[_vm._v(_vm._s(_vm.$t(("notifications.cta." + notification_key))))]),(notification.data)?_c('div',{staticClass:"pt-4 mt-auto"},[(notification.data.type == 'event' && !!notification.data.identifier)?_c('div',[_c('router-link',{staticClass:"flex flex-row items-center float-right space-x-2 text-sm font-semibold text-right text-brand-dark",attrs:{"to":{
          name: 'my-event',
          params: { uuid: notification.data.identifier }
        }}},[_c('span',{staticClass:"absolute inset-0"}),_c('span',[_vm._v(_vm._s(_vm.$t(("notifications.action." + (notification.data.type)))))]),_c('span',{staticClass:"flex items-center justify-center w-6 h-6 text-lg font-semibold leading-none rounded-full shadow-primary-blue bg-brand-primary text-brand-dark"},[_vm._v(">")])])],1):_vm._e()]):_vm._e()])}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }