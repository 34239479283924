<template>
  <div
    class="relative flex h-32 overflow-hidden rounded-lg bg-white p-4 shadow-md"
  >
    <h4 class="font-medium text-brand-dark">
      {{ title }}
    </h4>
    <div
      class="absolute bottom-4 right-4 z-20 flex h-10 items-center space-x-2"
    >
      <t-button
        :to="{ name: 'journal-index' }"
        class="flex h-full w-10 items-center justify-center rounded-full bg-white shadow-md"
        variant="link"
      >
        <icon-list class="h-5 w-5 fill-current text-brand-dark" />
      </t-button>
      <t-button
        @click="$emit('onPlusClick')"
        class="flex h-full w-10 items-center justify-center rounded-full bg-white shadow-md"
        variant="link"
      >
        <icon-plus class="h-4 w-4 text-brand-dark" />
      </t-button>
    </div>

    <slot />
  </div>
</template>

<script>
import IconPlus from "assets/icons/plus.svg";
import IconList from "assets/icons/journal.svg";

export default {
  components: {
    IconPlus,
    IconList,
  },

  props: {
    title: {
      type: String,
    },
  },
};
</script>

<style></style>
