<template>
  <section class="relative flex items-stretch gap-4 py-2 overflow-visible overflow-x-auto snap-x text-brand-dark"
    v-if="hasActiveNotifications">

    <!-- <button type="button" v-on:click="dismissAll"
      class="absolute z-40 items-stretch w-5 h-5 font-bold text-white focus:outline-none top-1 right-1"
      key="popup-close">
      <icon-close /> x <span>{{ countActiveNotifications }}</span>
    </button> -->

    <!-- <pre
      class="relative flex-shrink-0 w-11/12 h-full p-4 overflow-hidden bg-white rounded-lg shadow-md snap-start"><p class="text-brand-dark">{{ JSON.stringify(getNotifications, null, 2) }}</p></pre> -->

    <div v-for="[notification_key, notification] in Object.entries(getNotifications || {})" :key="notification_key"
      :class="{
        'w-11/12': countActiveNotifications > 1,
        'w-full': countActiveNotifications == 1,
      }" class="relative flex flex-col flex-shrink-0 h-auto p-4 overflow-hidden bg-white rounded-lg shadow-md snap-start"
      v-show="!!notification.active">
      <button type="button" v-on:click="dismissNotification(notification_key)"
        class="absolute z-40 items-stretch w-5 h-5 font-bold text-brand-dark-75 hover:text-brand-dark focus:outline-none top-2 right-2"
        key="popup-close">
        <icon-close />
      </button>
      <p class="max-w-full text-base text-semibold">{{ $t(`notifications.cta.${notification_key}`) }}</p>
      <div v-if="notification.data" class="pt-4 mt-auto">
        <div v-if="notification.data.type == 'event' && !!notification.data.identifier">
          <router-link :to="{
            name: 'my-event',
            params: { uuid: notification.data.identifier }
          }" class="flex flex-row items-center float-right space-x-2 text-sm font-semibold text-right text-brand-dark">
            <span class="absolute inset-0"></span>
            <span>{{ $t(`notifications.action.${notification.data.type}`) }}</span>
            <span class="flex items-center justify-center w-6 h-6 text-lg font-semibold leading-none rounded-full shadow-primary-blue bg-brand-primary text-brand-dark">&gt;</span>
          </router-link>
        </div>
        <!-- <p>{{ JSON.stringify(notification) }}</p> -->
      </div>
    </div>

  </section>
</template>

<script>
import { mapGetters } from "vuex";
// import IconEvents from "assets/icons/event-map-pin.svg";
import IconClose from "assets/icons/close.svg";
// import IconLightbulb from "assets/icons/lightbulb.svg";
// import IconChecklist from "assets/icons/checklist.svg";

export default {
  name: "NotificationSlider",

  components: {
    IconClose,
    // IconChecklist
  },

  /* props: {
  }, */

  /* data() {
    return {
    };
  }, */

  

  mounted() {
    this.$store.dispatch('checkNotifications')
  },

  methods: {
    dismissAll() {
      this.$store.dispatch('dismissAllNotifications');
    },
    dismissNotification(key) {
      this.$store.dispatch('dismissNotification', key);
    },
  },

  computed: {
    /* futureEventTimeHumanized() {
      return this.$moment
        .duration(
          this.$moment(this.firstOngoingEvent.date_from).diff(this.$moment()),
          "milliseconds"
        )
        .humanize(true);
    }, */
    ...mapGetters(['getNotifications', 'hasActiveNotifications', 'countActiveNotifications'])
  },
};
</script>

<style scoped></style>
