<template>
  <div class="flex flex-col w-full p-4 space-y-1 bg-white rounded-lg shadow-md">
    <div>
      <p class="font-semibold">{{ $t('profile.buddies.empty') }}</p>

      <p class="text-sm text-gray-400">{{ $t('profile.safety-buddies--intro') }}</p>

      <t-button @click="safetyBuddyOpen = true" variant="nothing"
        class="grid w-8 h-8 mt-4 font-bold text-white rounded-full place-items-center bg-brand-dark">
        +
      </t-button>
    </div>

    <add-safety-buddy @close="safetyBuddyOpen = false" v-if="safetyBuddyOpen" />
  </div>
</template>

<script>
// import { mapGetters } from "vuex";
import AddSafetyBuddy from "@/components/Settings/AddSafetyBuddy";

export default {
  name: "Buddy",

  components: {
    AddSafetyBuddy,
  },

  mounted() {
    this.init();
  },

  data() {
    return {
      safetyBuddyOpen: false,
    };
  },

  methods: {
    init() {
      // console.log("hoi");
    },
  },

  /* computed: {
    ...mapGetters(["getBuddies"]),
  }, */
};
</script>

<style scoped></style>
