import { render, staticRenderFns } from "./EventStats.vue?vue&type=template&id=9b7d80b8&scoped=true&"
import script from "./EventStats.vue?vue&type=script&lang=js&"
export * from "./EventStats.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b7d80b8",
  null
  
)

export default component.exports