<template>
  <list-item class="w-full" :multiline="true">
    <template v-slot:default>
      <span class="max-w-full font-medium text-black">{{
        task.description
      }}</span>
    </template>
    <template v-slot:end>
      <button
        type="button"
        class="flex items-start justify-center w-full h-full transition duration-150 ease-in-out rounded-bl-lg group-focus:bg-gray-200"
        @click="editTask(task)"
      >
        <svg
          class="w-5 h-5 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-500 group-focus:text-gray-500"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"
          />
        </svg>
      </button>
    </template>
  </list-item>
</template>

<script>
import ListItem from "components/ListItem.vue";

export default {
  components: {
    ListItem
  },

  props: {
    task: {
      type: Object
    }
  },

  methods: {
    editTask() {
      this.$emit("editTask", this.task);
    }
  }
};
</script>
