var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative flex h-32 p-4 overflow-hidden bg-white rounded-lg shadow-md",class:{ 'opacity-25': _vm.disabled },on:{"click":_vm.handleRouteChange}},[_c('div',[(_vm.event.is_future_event)?_c('h4',{staticClass:"font-bold uppercase text-brand-dark"},[_vm._v(" "+_vm._s(_vm.$t("events.coming_up"))+" ")]):_c('h4',{staticClass:"font-medium text-brand-dark"},[_vm._v(" "+_vm._s(_vm.$t( _vm.isCheckedIn ? "dashboard.checked_in_party" : "dashboard.ready_to_party", { username: _vm.getUsername } ))+" ")]),_c('p',{staticClass:"text-xs text-gray-400"},[_vm._v(_vm._s(_vm.event.name))])]),_c('div',{staticClass:"absolute flex items-center h-10 space-x-2 bottom-4 right-4"},[(!_vm.event.is_future_event)?_c('t-button',{staticClass:"z-20 flex items-center justify-center w-10 h-full m-0 bg-white rounded-full",class:{
        'bg-white shadow-md': !_vm.isCheckedIn,
        'bg-brand-lightblue shadow-primary-blue': _vm.isCheckedIn && _vm.currentEvent === _vm.event.uuid,
      },attrs:{"as":"router-link","to":{
        name: 'my-event',
        params: { uuid: _vm.event.uuid, autoCheckin: true },
      },"disabled":_vm.disabled,"variant":"link"}},[_c('icon-check-in',{staticClass:"w-5 h-5 text-brand-dark"})],1):_vm._e(),_c('t-button',{staticClass:"z-20 flex items-center justify-center w-10 h-full m-0 bg-white rounded-full shadow-md",attrs:{"to":{
        name: 'my-event-checklist',
        params: { uuid: _vm.event.uuid },
      },"disabled":_vm.isCheckedIn && _vm.currentEvent !== _vm.event.uuid,"variant":"link"}},[_c('icon-list',{staticClass:"w-5 h-5 text-brand-dark"})],1),_c('t-button',{staticClass:"z-20 flex items-center justify-center w-10 h-full m-0 bg-white rounded-full shadow-md",attrs:{"to":{
        name: 'my-event-preparation-tool',
        params: { uuid: _vm.event.uuid },
      },"variant":"link"}},[_c('icon-lightbulb',{staticClass:"w-5 h-5 text-brand-dark"})],1)],1),_c('icon-events',{staticClass:"absolute z-10 opacity-25 fill-current -top-2 -right-10 h-36 text-brand-primary"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }